.al-b-info-item__wrapper {
    left: 30%;
    position: absolute;
    top: 30%;
    z-index: 10
}

.al-b-info-item {
    cursor: pointer;
    position: relative;
    z-index: 1
}

.al-b-info-item .b-icon {
    background-color: #fff;
    border-radius: 50%;
    display: block;
    height: 32px;
    -webkit-transition: transform .2s;
    -o-transition: transform .2s;
    transition: transform .2s;
    width: 32px
}

.al-b-info-item:hover .b-icon {
    -webkit-box-shadow: 0 .625rem 3rem .188rem rgba(23,25,36,.6);
    box-shadow: 0 .625rem 3rem .188rem rgba(23,25,36,.6);
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2)
}

.al-b-info-item:hover+.al-b-info-item-tooltip__wrap {
    opacity: 1
}

.al-b-info-item-tooltip__wrap {
    left: 145%;
    min-width: 135px;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition-duration: .3s;
    -o-transition-duration: .3s;
    transition-duration: .3s;
    -webkit-transition-property: all;
    -o-transition-property: all;
    transition-property: all;
    -webkit-transition-timing-function: ease;
    -o-transition-timing-function: ease;
    transition-timing-function: ease
}

.al-b-info-item-tooltip {
    color: #fff;
    font-family: GraphikLCGMedium,sans-serif;
    font-size: .875rem;
    letter-spacing: .066rem;
    line-height: 1.5rem;
    position: relative;
    text-transform: uppercase
}

.al-b-info-item-tooltip:after {
    -webkit-box-shadow: 0 .375rem 10.625rem 5rem rgba(23,25,36,.47);
    box-shadow: 0 .375rem 10.625rem 5rem rgba(23,25,36,.47);
    content: "";
    display: block;
    left: 50%;
    position: absolute;
    top: 50%;
    z-index: -1
}