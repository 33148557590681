@import "template.css";
@import "reset.css";
@import "video.js/dist/video-js.css";

:root {
    --red: #EB5757;
    --green: #27AE60;
    --light: #FFF5EB;
    --dark-gold: #C59669;
    --light-gold: #E3B486;
    --black: #000;
    --dark-green: #0B2B37;
    --white: #FFF;
    --gray1: #05050A;
    --gray3: #828282;
    --gray4: #BDBDBD;
    --dark-green-gradient: linear-gradient(90deg, #007762 0%, #00CBD8 100%);
    --dark-top-gradient: linear-gradient(180deg, rgb(0 0 0 / 70%) 0%, rgb(0 0 0 / 0%) 100%);
    --golden-gradient: linear-gradient(45deg, #DAAF85 42.76%, #AA7948 100%);
    --golden-gradient-left: linear-gradient(90deg, #976744 0%, #C9B080 100%);
    --padding-section: 150px;
}

* {
    white-space: pre-line;
}


html {
    scroll-behavior: smooth;
}

body {
    overflow-x: hidden;
    white-space: initial!important;
}
*[class^="comagic"]{
    white-space: initial!important;
}

main {
    margin: 0 auto;
}

.mb-170 {
    margin-bottom: 150px;
}

.container {
    position: relative;
    width: 100%;
    padding: 0 min(150px, 7.8125vw);
}

.filterBtnAll button span.inter {
    font-size: 13px!important;
}
.mobile_slider {
    display: none;
}
@media (width <= 1440px) {
    .container {
        padding: 0 5.0694vw;
    }
}

@media (width <= 1200px) {
    .container {
        padding: 0 42px;
    }
    .mb-170 {
        margin-bottom: 150px;
    }
    
}

@media (width <= 600px) {
    .container {
        padding: 0 12px;
    }
    .mb-170 {
        margin-bottom: 100px;
    }
    .desktop_slider {
        display: none;
    }
    .mobile_slider {
        display: block;
    }
}

a:hover {
    text-decoration: underline;
}

.link {
    color: var(--dark-gold);
    text-decoration: underline!important;
}

.page {
    display: flex;
    flex-direction: column;
    gap: 150px;
    margin-bottom: 150px;
    position: relative;
}

@media (width <= 1200px) {
    .page {
        gap: 100px
    }
}

@media (width <= 600px) {
    .page {
        gap: 70px
    }
}

#neva-haus {
    position: relative;
    cursor: pointer;
    z-index: 4;
}

.pb-0 {
    padding-bottom: 0;
}

.mb-0 {
    margin-bottom: 0;
}

@keyframes blink {
    0% {
        transform: translate(-100%, -5px) skew(-45deg);
    }
    100% {
        transform: translate(100%, -5px) skew(-45deg);
    }
}

.error-page {
    margin-top: 162px;
    margin-bottom: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.error-breadcrumbs {
    margin-bottom: 100px;
}

.error-container {
    display: flex;
    margin-bottom: 100px;
    width: 902px;
    gap: 67px
}

.error-icon {
    width: 385px;
    flex-shrink: 0;
}

.error-header {
    margin-bottom: 60px;
}

.error-footer {
    max-width: 252px;
}

.error-footer a:hover {
    text-decoration: none;
}

@media (width <= 1200px) {
    .error-container {
        width: min(902px, 75.17vw);
    }
}

@media (width <= 768px) {
    .error-container {
        flex-direction: column;
        align-items: center;
        padding: 0 12px;
        gap: 30px;
    }

    .error-header {
        text-align: center;
        margin-bottom: 50px;
    }

    .error-footer {
        margin: 0 auto;
    }

    .error-icon {
        width: 100%;
    }
}

.inter {
    font-family: Inter, sans-serif;
}

.raleway {
    font-family: Raleway, sans-serif;
}

.splide {
    position: relative;
    padding: 0!important;
}
.progressModal {
    background: none!important;
    padding: 0!important;
    margin: 0!important;
    width: 100%;
    height: 100%;
    max-width: initial!important;
    display: flex;
    align-items: center;
  }
  
  .progressModal .modal-wrapper {
    background-color: #fff;
    max-width: 80%;
    padding: 70px 100px;
    margin: 0 auto;
    margin-top: 100px;
  }
  .videoModal .modal-wrapper{
    max-width: 800px;
    background-color: transparent;
    padding: 0;
  }
  .videoModal iframe {
    width: 100%;
    height: 450px;
  }
  .progressClose {
    border-radius: 50%;
    border: 2px solid #fff!important;
    padding: 13px!important;
    right: 25px!important;
    top: 25px!important;
  }
  .progressClose svg{
    fill: #fff;
  }
  
  .progressModal .row {
    display: grid;
    grid-template-columns: 20% 70%;
    grid-gap: 0px 100px;
    margin-top: 30px;
  }
  
  
  .progressModal  .tab-nav-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 45px);
    grid-gap: 15px;
  }
  
  .progressModal  .tab-nav-item {
    display: block;
    cursor: pointer;
    background-color: var(--light-gold);
    color: #fff;
    border-radius: 50px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    opacity: .5;
  }
  .progressModal  .tab-nav-item.active {
    opacity: 1;
  }
  
  .progressModal .tab-nav-content iframe{
    width: 100%;
    height: 450px;
  
  }
  .iframe-wrapper {
    width: 100%;
    height: 450px;
    overflow: hidden;
  }
  .progressModal h2{
    font-size: clamp(38px, 2.5vw, 48px);
    font-style: normal;
    font-weight: 300;
  }
  
  @media (width <= 1200px) {
    .progressModal .row {
        display: block;
        grid-template-columns: 100% 100%;
        grid-gap: 0px 100px;
        margin-top: 30px;
    }
    .progressModal  .tab-nav-list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 45px);
        grid-gap: 15px;
        margin-bottom: 30px;
    }
  
  }
  
  @media (width <= 768px) {
  
    .progressModal .modal-wrapper {
        background-color: #fff;
        max-width: 80%;
        padding: 50px 50px;
        margin: 0 auto;
        margin-top: 100px;
    }
    .progressModal .tab-nav-content iframe{
        width: 100%;
        height: 100%;
    }
    .videoModal .modal-wrapper{
        background-color: transparent;
        max-width: 80%;
        padding: 50px 50px;
        margin: 0 auto;
        margin-top: 100px;
    }
  }
  @media (width <= 560px) {
    .progressModal .modal-wrapper {
        background-color: transparent;
        padding: 0px!important;
        margin: 0 auto;
        max-width: 100%;
        margin-top:70px
    }
    .progressModal h2 {
        font-size: 30px;
        color: #fff;
        max-width: 80%;
        margin: 0 auto;
    }
    .progressModal .tab-nav-list {
        width: 80%;
        margin: 0 auto;
        margin-bottom: 30px;
    }
    .videoModal .modal-wrapper{
        margin-top:150px
    }
  }
  @media (width <= 480px) {
    .progressModal  .tab-nav-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 45px);
        grid-gap: 15px;
        margin-bottom: 30px;
    }
  
  }

.active .success_msg_visible {
    display: flex!important;
}
:disabled {
    cursor: default!important;
}